$black-color:#000000;
$white-color: #FFFFFF;
$color-primary:#5200FF;
$grey-color:#F7F8FC;
$blue-color:#0B071C;
$border-color:#EBEDF3;

$xs: 374px;
$sm: 576px;
$md: 767px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1400px;

.header {
    position: fixed;
    top: 0;
    max-height: 138px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white-color;
    z-index: 99;
    .col{
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 24px 0px;
        align-items: center;
        transition: background-color 0.5s, padding 1s;
        @media screen and (max-width:$xl) {
            padding: 15px 0px;
        }
        a{
            display: flex;
            align-items: center;
            color: $black-color;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            text-decoration: none;
            margin: 0px;
            &:hover{
                color: $color-primary;
            }
            &.active{
                color: $black-color;
                &.current{
                    color: $color-primary;
                }
            }
            img{
                @media screen and (max-width:$lg) {
                    height: 35px;
                }
                @media screen and (max-width:$md) {
                    height: 25px;
                }
            }
        }
    }
    .menu-icon {
        display: none;
        position: absolute;
        top: 28px;
        right: 0;
        font-size: 1.8rem;
        cursor: pointer;
        width: 25px;
        height: 25px;
        i{
            display: flex;
            font-size: 25px;
            justify-content: end;
        }
        @media screen and (max-width:$lg){
            display: block;
        }
        @media screen and (max-width:$md){
            top: 21px;
        }
    }
    .navbar{
        display: flex;
        align-items: center;
        @media screen and (max-width:$lg){
            background-color: $white-color;
            align-items: flex-start;
            .nav{
                flex-direction: column;
            }
        }
        &.active{
            left: -30px;
            @media screen and (max-width:$md){
                left: -20px;
            }
        }
        @media screen and (max-width:$lg){
            flex-direction: column;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 77px;
            left: -110%;
            opacity: 1;
            transition: all 0.5s ease;
            padding: 0px 30px;
        }
        @media screen and (max-width:$md){
            padding: 0px 20px;
            top: 64px;
        }
        .nav{
            display: flex;
            align-items: center;
            gap: 24px;
            @media screen and (max-width: 1600px) {
                gap: 20px;
            }
            @media screen and (max-width: $xxl) {
                gap: 16px;
            }
            @media screen and (max-width: 1280px) {
                gap: 12px;
            }
            a, .dropdown-container {
                color: $black-color;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-decoration: none;
                margin: 0;
                
                &:hover {
                    color: $color-primary;
                }
            }
            .dropdown-container {
                position: relative;
                
                .dropdown-trigger {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    
                    i {
                        font-size: 12px;
                        margin-top: 2px;
                    }
                }
                .dropdown-menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background: $white-color;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    padding: 8px 0;
                    min-width: 200px;
                    z-index: 1000;
                    a {
                        display: block;
                        padding: 8px 16px;
                        white-space: nowrap;
                        
                        &:hover {
                            background-color: $grey-color;
                        }
                    }
                }
            }
            .current {
                color: $color-primary;
            }
        }
    }
        button{
            padding: 11px 24px;
            color: $black-color;
            z-index: 9;
            background-image: linear-gradient(to right, #5200FF, #00BBFF, #5FEFDE);
            position: relative;
           
            &:hover{
                z-index: auto;
                color: $white-color;
                border: transparent;
               
            }
            &::before{
                    content: "";
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    left: 1px;
                    background-color: $white-color;
                    z-index: -1;
                    border-radius: 50px;
            }
            @media screen and (max-width: $lg) {
                padding: 12px 30px;
                margin-right: 35px;
            }
            @media screen and (max-width: $md){
                font-size: 14px;
                padding: 7px 12px;
            }
            &:hover{
                border: 1px solid transparent;
                background-color: transparent;
                background-image: linear-gradient(to right, #5200FF, #00BBFF, #5FEFDE);
            }
        }
      }
  