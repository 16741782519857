.ats-resource {
  padding: 120px 0 60px;
  background-color: #6B46C1;
  min-height: 100vh;

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .ats-content {
    background: white;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 10px;
    font-family: 'Calibri', sans-serif;

    h1 {
      font-size: 1.3rem;
      color: #002147;
      margin-bottom: 10px;
      font-weight: 1200;
      text-align: center;
      letter-spacing: 0.1em;
    }

    .intro {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 10px;
      color: #666;
    }

    section {
      margin-bottom: 30px;

      h2 {
        font-size: 1.1rem;
        color: #6B46C1;
        margin-bottom: 15px;
        font-weight: bold;
        letter-spacing: 0.05em;
      }

      ul, ol {
        list-style-position: outside;
        padding-left: 20px;
        margin-bottom: 15px;
        font-size: 1.1rem;

        li {
          margin-bottom: 12px;
          line-height: 1.5;

          strong {
            font-weight: bold;
          }

          ul {
            margin-top: 8px;
            margin-bottom: 8px;
          }
        }
      }

      p {
        font-size: 1.2rem;
        line-height: 1.6;
        margin-bottom: 15px;
        font-weight: normal;
        color: #333;
      }
    }

    .conclusion {
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid #eee;

      p {
        font-size: 1.1rem;
        line-height: 1.6;
      }
    }
  }
}

@media (max-width: 768px) {
  .ats-resource {
    padding: 100px 0 40px;

    .ats-content {
      padding: 20px;
      margin: 10px;

      h1 {
        font-size: 1.8rem;
      }

      section {
        h2 {
          font-size: 1.2rem;
        }
      }
    }
  }
} 