$black-color:#000000;
$white-color: #FFFFFF;
$color-primary:#5200FF;
$grey-color:#F7F8FC;
$blue-color:#0B071C;
$border-color:#EBEDF3;

  $xs: 374px;
  $sm: 576px;
  $md: 767px;
  $lg: 992px;
  $xl: 1200px;
  $xxl: 1400px;

.is-fullheight{
    position: relative;
    display: flex;
    align-items: center;
    .container{
        max-width: 708px;
        padding: 30px 30px;
       
        @media screen and (max-width:$md){
            padding: 30px 20px;
          }
    }
    .title{
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 24px;
        @media screen and (max-width:$md){
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 15px;
        }
    }
    .row{
        filter: drop-shadow(0px 20px 24px rgb(0 0 0 / 25%));
        background-color: $white-color;
        border-radius: 24px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .col{
            width: 100%;
            padding: 40px 40px 40px 40px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-content: space-between;
            @media screen and (max-width:$lg){
                width: 100%;
            }
            @media screen and (max-width:$md){
                padding: 30px 20px;
            }
            
            .box-title{
                @media screen and (max-width:$md){
                    text-align: center;
                }
                img{
                    margin-bottom: 24px;
                    @media screen and (max-width:$md){
                        width: 163px;
                        margin-bottom: 18px;
                    }
                }
            }
            form{
                justify-content: end;
                display: flex;
                flex-wrap: wrap;
                .field-group{
                    display: flex;
                    gap: 24px;
                    width: 100%;
                    margin-bottom: 15px;
                    @media screen and (max-width:$md){
                        flex-wrap: wrap;
                        gap: 0px;
                    }
                    .field{
                        width:calc(50% - 12px);
                        @media screen and (max-width:$md){
                            width:100%;
                        }
                        label{
                            font-size: 13px;
                            line-height: 16px;
                            margin:0px 0px 8px 16px;
                            display: inline-block;
                            color: #000;
                            font-weight: 500;
                        }
                    }
                }
                .field{
                    margin-bottom: 24px;
                    width: 100%;
                    &:nth-child(2){
                        margin-bottom: 16px;
                        @media screen and (max-width:$md){
                            margin-bottom: 24px;
                        }
                    }
                    &.checkbox-wrap{
                        &:nth-last-child(3){
                            margin-bottom: 19px;
                        }
                        .control{
                            position: relative;
                            display: flex;
                            gap: 12px;
                            input[type="checkbox"]{
                                display: inline-block;
                                height: 21px;
                                min-width: 21px;
                                opacity: 0;
                                width: 21px;
                                top: 0px;
                                position: relative;
                                &:checked{
                                    +label{
                                        &::before{
                                            background-color: $color-primary;
                                        }
                                        &::after{
                                            content: "";
                                            display: block;
                                            position: absolute;
                                            top: 4px;
                                            left: 9px;
                                            width: 5px;
                                            height: 11px;
                                            border: solid #FFFFFF;
                                            border-width: 0 2px 2px 0;
                                            transform: rotate(45deg);
                                        }
                                    }
                                }
                            }
                            label{
                                cursor: pointer;
                                font-size: 16px;
                                font-weight: 600;
                                a{
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: $black-color;
                                    margin: 0px 5px;
                                    &:hover{
                                        color: $color-primary;
                                    }
                                }
                                &::before{
                                    content: "";
                                    position: absolute;
                                    left: 0px;
                                    right: 0px;
                                    top: 0px;
                                    bottom: 0px;
                                    min-width: 20px;
                                    height: 20px;
                                    background-color: transparent;
                                    border: 2px solid rgba(0, 0, 0, 0.06);
                                    display: inline-block;
                                    border-radius: 6px;
                                    width: 20px;
                                }
                                
                            }
                        }
                    }
                    &.file-upload{
                        font-weight: 600;
                        label{
                            cursor: pointer;
                            background-color: rgba(82, 0, 255, 0.06);
                            color: $color-primary;
                            font-size: 16px;
                            font-weight: 600;
                            border-radius: 12px;
                            width: 100%;
                            min-height: 48px;
                            margin-top: 14px;
                            padding: 11px 40px 11px 16px;
                            border: 0;
                            line-height: 26px;
                            font-family: "Manrope";
                            text-align: center;
                            position: relative;
                            img{
                                position: absolute;
                                right: 16px;
                                width: 16px;
                                height: 16px;
                                top: 15px;
                                object-fit: contain;
                            }
                        }
                    }
                }
                .info{
                    padding: 20px;
                    border-radius: 24px;
                    background-color: rgb(0 0 0 / 3%);
                    margin-bottom: 20px;
                    p{
                    }
                    ol{
                        margin: 0px 0px 20px 0px;
                        padding-left: 15px
                    }
                }
            }
            p{
                color: rgb(0 0 0 / 60%);
                font-size: 16px;
                line-height: 24px;
                max-width: 80%;
                margin-bottom: 10px;
                @media screen and (max-width:$md){
                    max-width: 100%;
                    margin-bottom: 20px;
                }
                &.is-danger{
                    font-size: 14px;
                    color: #ff0039;
                    margin-bottom: 0px;
                    line-height: normal;
                }
            }
            
        }
    }
}
.submitmodal{
    position: fixed;
    z-index: 99;
    filter: none;
    padding: 30px 20px!important;
    border-radius: 20px;
    background-color: $white-color;
    margin: 0px auto;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    box-shadow: 1px 5px 18px 11px #8080801c;
    .box{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        img{
            width: 30px;
            height: 30px;
            margin: 0px auto 10px auto;
        }
    }
    
    @media screen and (max-width:$sm){
       max-width: 275px; 
    }
    p{
        margin-bottom: 0px;
        color: $color-primary;
        text-align: center;
    }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
  padding: 12px 12px;
  
  .checkbox-wrap {
    display: flex;
    align-items: center;
    position: relative;
    
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #E5E7EB;
      border-radius: 6px;
      margin-right: 12px;
      cursor: pointer;
      position: relative;
      background: #FFFFFF;

      &:checked {
        background: #4F46E5;
        border-color: #4F46E5;

        &::after {
          content: '';
          position: absolute;
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &:hover {
        border-color: #4F46E5;
      }

      &:disabled {
        background-color: #F3F4F6;
        border-color: #D1D5DB;
        cursor: not-allowed;
        
        &:hover {
          border-color: #D1D5DB;
        }
        
        & + label {
          color: #9CA3AF;
          cursor: not-allowed;
        }
      }
    }
    
    label {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #1F2937;
      cursor: pointer;
      user-select: none;
    }
  }
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  padding-top: 24px;
}

@mixin horizontal-line {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #E5E7EB;
}

.section-divider {
  position: relative;
  padding: 12px 0; 

  &::before {
    @include horizontal-line;
    top: 0;
  }
}

.drag-drop-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  
  &.drag-active {
    border-color: #5200FF;
    background-color: rgba(82, 0, 255, 0.05);
  }
  
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #f0f0f0;
  }
  
  .drag-drop-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    i {
      font-size: 2rem;
      color: #5200FF;
      margin-bottom: 10px;
    }
    
    p {
      margin-bottom: 5px;
      font-size: 1rem;
      color: #333;
    }
    
    .file-types {
      font-size: 0.8rem;
      color: #888;
    }
  }
}

.selected-files {
  margin-top: 10px;
  
  .file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 8px;
    
    p {
      margin: 0;
      font-size: 0.9rem;
      color: #333;
      word-break: break-all;
    }
    
    img {
      flex-shrink: 0;
    }
  }
}

.field.file-upload {
  .control {
    margin-bottom: 15px;
  }
}
